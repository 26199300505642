.region {
  fill: #24243c;
  stroke: #000000;
  stroke-width: 2;
  animation: multiColorPulse 6s ease infinite;
}

@keyframes multiColorPulse {
  0% {
    stroke: #24243c; /* Томатный */
  }
  25% {
    stroke: #4682b4; /* Стальной синий */
  }
  50% {
    stroke: #1a128d; /* Ярко-зелёный */
  }
  75% {
    stroke: #fad816; /* Оранжевый */
  }
  100% {
    stroke: #24243c; /* Возвращаемся к томатному */
  }
}

.region.hovered {
  fill: #707070; /* Цвет при наведении */
}


.region.highlighted {
  fill: #a5a5aa; /* Цвет выделенной области */
}

.svg-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.region-title {
  position: absolute;
  top: calc(-3rem);
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.2rem;
  font-family: 'Arial', sans-serif;
  pointer-events: none;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s ease;
}
