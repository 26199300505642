.quiz-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Arial, sans-serif;
  z-index: 1000;
}

.quiz-header {
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

.quiz-content {
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.quiz-content.larger-step {
  max-width: 600px;
  min-height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  position: absolute; /* Centers the form vertically */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the form exactly in the middle */
}

.quiz-footer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  border-radius: 12px;
}

.progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
  font-size: 14px;
  color: #ccc;
}

.progress-bar {
  flex-grow: 1;
  height: 8px;
  background: #333;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #4a4a72;
  transition: width 0.3s ease;
}

.next-button {
  background: #4a4a72;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-button.disabled {
  background: #555;
  cursor: not-allowed;
}

.next-button:hover:not(.disabled) {
  background: #353546;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px;
}

.option-btn {
  background: #242436;
  color: #fff;
  border: 2px solid transparent;
  padding: 15px 20px;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  transition: all 0.3s ease;
}

.option-btn:hover {
  background: #353546;
}

.option-btn.selected {
  background: #1c1c2d;
  border-color: #fff;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: transparent;
}

.radio-circle:hover {
  background: #4a4a72;
}

.option-btn.selected .radio-circle {
  background: #1c1c2d;
}

.quiz-phone-input-container {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  padding: 0 15px;
  box-sizing: border-box;
}

.quiz-phone-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #242436;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
}

.quiz-phone-input-wrapper.larger-phone-input {
  padding: 27px; /* Увеличенная высота на 1.5x */
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
}

.quiz-phone-input {
  flex-grow: 1;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 18px;
  width: 100%;
}

.quiz-phone-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ccc;
  text-align: left;
  width: 100%;
}

.consent-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-button {
  background: #353546;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.submit-button:disabled {
  background: #555;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background: #4a4a72;
}

.thank-you-page {
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 12px;
}

.thank-you-page h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.thank-you-page p {
  font-size: 16px;
  color: #ccc;
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .quiz-content-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .quiz-header {
    font-size: 16px;
    padding: 15px;
  }

  .quiz-content {
    padding: 15px;
  }

  .option-btn {
    padding: 12px;
    font-size: 16px;
  }

  .next-button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .quiz-phone-input-wrapper {
    padding: 20px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .quiz-content-wrapper {
    padding: 10px;
  }

  .quiz-header {
    font-size: 14px;
    padding: 10px;
  }

  .quiz-content {
    padding: 10px;
  }

  .option-btn {
    padding: 10px;
    font-size: 14px;
  }

  .next-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .quiz-phone-input-wrapper {
    padding: 18px;
  }

  .submit-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}
