.download-section-wrapper {
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.download-section-container {
  width: 100%;
  max-width: 1300px;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
}

.download-catalog-container {
  position: relative;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  padding: 60px 80px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  margin: 0;
  min-height: 600px;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 40px;
  box-sizing: border-box;
}

.download-catalog-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 40px;
  z-index: 2;
}

.download-catalog-left h1 {
  font-size: 36px;
  color: #fff;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.download-catalog-left h2 {
  font-size: 36px;
  color: #fff;
  margin: 5px 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.catalog-description {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 24px;
  opacity: 0.9;
  max-width: 400px;
}

.download-catalog-right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  justify-content: center;
  padding-top: 60px;
  transform: translateX(-80px);
}

.catalog-title {
  position: absolute;
  top: 0;
  left: -80px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Уникальные стили для контактов в каталоге */
.catalog-contact-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.catalog-phone-number {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.catalog-phone-number:hover {
  color: #C19D65;
}

.catalog-phone-icon {
  display: none;
  color: #fff;
  font-size: 1.3rem;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
}

.catalog-phone-icon:hover {
  color: #C19D65;
}

.catalog-input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 2;
  color: rgba(255, 255, 255, 0.7);
}

.phone-input-container {
  position: relative;
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
  background: rgba(30, 30, 51, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.phone-input-container:focus-within {
  border-color: #C19D65;
  box-shadow: 0 0 0 1px #C19D65;
  background: rgba(30, 30, 51, 0.95);
}

.country-code {
  font-size: 14px;
  opacity: 0.8;
}

.phone-input-container input {
  width: 100%;
  padding: 16px 20px 16px 80px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  outline: none;
  caret-color: #fff;
}

.phone-input-container input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.5px;
}

.phone-input-container input.error {
  border-color: #ff4e4e;
}

.download-button {
  width: 100%;
  max-width: 360px;
  background: #C19D65;
  color: #fff;
  border: none;
  padding: 16px 40px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}

.download-button:hover {
  background: #d4b07c;
  transform: translateY(-2px);
}

.catalog-preview {
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 600px;
  transform: rotate(-10deg);
  z-index: 1;
}

.catalog-image {
  width: 100%;
  filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.4));
  transform-origin: right bottom;
}

@media (max-width: 1200px) {
  .download-section-container {
    padding: 0;
  }

  .download-catalog-container {
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .download-catalog-left {
    padding-right: 0;
  }

  .download-catalog-right {
    transform: none;
    padding-top: 40px;
  }

  .catalog-title {
    left: 0;
  }

  .catalog-preview {
    position: relative;
    right: auto;
    bottom: auto;
    width: 80%;
    max-width: 400px;
    transform: none;
    margin: 30px auto 0;
  }
}

@media (max-width: 768px) {
  .download-section-container {
    padding: 0;
  }

  .download-catalog-container {
    padding: 20px;
    min-height: auto;
    grid-template-columns: 1fr;
  }

  .download-catalog-left {
    padding-right: 0;
    text-align: center;
  }

  .download-catalog-left h1,
  .download-catalog-left h2 {
    font-size: 28px;
  }

  .catalog-description {
    font-size: 14px;
    max-width: 100%;
    margin: 20px auto 0;
  }

  .download-catalog-right {
    padding-top: 30px;
    transform: none;
    width: 100%;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  .catalog-title {
    position: relative;
    left: 0;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .phone-input-container {
    width: 100%;
    max-width: 320px;
    margin-bottom: 15px;
  }

  .phone-input-container input {
    padding: 14px 20px 14px 70px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
  }

  .catalog-input-icon {
    left: 12px;
    padding: 0 8px;
    gap: 6px;
  }

  .country-code {
    font-size: 12px;
  }

  .catalog-phone-icon {
    display: block;
  }

  .catalog-phone-number {
    display: none;
  }
}

@media (max-width: 360px) {
  .download-catalog-container {
    padding: 15px;
  }

  .download-catalog-left h1,
  .download-catalog-left h2 {
    font-size: 24px;
  }

  .catalog-description {
    font-size: 13px;
  }

  .phone-input-container input {
    font-size: 13px;
  }

  .phone-input-container,
  .download-button {
    max-width: 280px;
  }
}

/* Добавляем стили для чекбокса и политики конфиденциальности */
.privacy-policy-container {
  width: 100%;
  max-width: 360px;
  margin-bottom: 15px;
}

.privacy-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}

.privacy-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 2px;
  height: 16px;
  width: 16px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  transition: all 0.2s ease;
}

.privacy-checkbox:hover input ~ .checkmark {
  border-color: rgba(255, 255, 255, 0.5);
}

.privacy-checkbox input:checked ~ .checkmark {
  background-color: #C19D65;
  border-color: #C19D65;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.privacy-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.privacy-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  line-height: 1.4;
}

.privacy-text a {
  color: #C19D65;
  text-decoration: none;
  transition: color 0.3s ease;
}

.privacy-text a:hover {
  color: #d4b07c;
}

.download-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
}

.download-button.disabled:hover {
  background: #C19D65;
  transform: none;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    max-width: 320px;
  }
}

@media (max-width: 360px) {
  .privacy-policy-container {
    max-width: 280px;
  }
  
  .privacy-text {
    font-size: 11px;
  }
}
