.grid-item {
  position: relative;
  width: 100%;
  padding-top: 70%;
  background-color: #1a1a2e;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Легкая тень */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-item-title {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(90deg, #2b5876, #4e4376);
  padding: 8px 12px;
  border-radius: 8px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(26, 26, 46, 0.5) 0%, rgba(26, 26, 46, 0.1) 100%);
  z-index: 0;
}

.hover-overlay {
  position: absolute;
  bottom: 0; /* Текст внизу карточки */
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%); /* Мягкий градиент */
  display: flex;
  flex-direction: column; /* Расположить строки друг под другом */
  align-items: flex-start; /* Выравнивание по левому краю */
  justify-content: flex-start;
  color: #fff;
  opacity: 0;
  padding: 10px 15px; /* Отступы */
  transition: opacity 0.3s ease, background 0.3s ease; /* Плавный переход */
  z-index: 2;
}

.grid-item:hover .hover-overlay {
  opacity: 1; /* Показываем содержимое */
}

.overlay-content {
  text-align: left;
  line-height: 1.6; /* Пространство между строками */
  font-family: 'Roboto', sans-serif;
  color: #e5e7eb; /* Светло-серый для текста */
}

.overlay-content p {
  margin: 0.4rem 0; /* Расстояние между строками */
  font-size: 1rem; /* Размер текста */
  color: #e5e7eb; /* Светло-серый цвет для общего текста */
}

.overlay-content p span.highlight {
  font-weight: 600; /* Полужирный текст для выделений */
  color: #ffffff; /* Белый цвет для выделений */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); /* Лёгкая тень для читаемости */
}

.overlay-content p span.highlight:hover {
  color: #d4d4d8; /* Нежно-серый при наведении */
  transition: color 0.3s ease; /* Плавное изменение цвета */
}
