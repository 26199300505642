.featured-properties {
  margin: 40px 0;
  text-align: center;
  color: #fff;
}

/* Контейнер для трех блоков */
.property-container {
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease;
  overflow: hidden;
}

/* Общие стили для каждого property-блока */
.property-block {
  flex: 1;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  transition: flex 0.5s ease;
  cursor: pointer;
  box-sizing: border-box;
  height: 300px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Контейнер для заголовка и кнопки */
.property-content {
  z-index: 1;
  text-align: left;
}

/* Заголовок */
.property-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}

/* Кнопки */
.property-button {
  background-color: #2b2b42;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px; 
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 
    background-color 0.3s ease,
    transform 0.2s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center; /* Выравнивание текста и стрелки */
  justify-content: center;
}

.arrow-icon {
  margin-left: 8px; /* Отступ между текстом и стрелкой */
}

.property-button:hover {
  background-color: #26263a;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.property-button:active {
  transform: scale(0.97);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Ховер-эффекты при наведении на карточки */
.property-block:hover {
  flex: 1.5;
  background-color: rgba(255, 255, 255, 0.2);
}
.property-container:hover .property-block {
  flex: 0.75;
}
.property-container:hover .property-block:hover {
  flex: 1.5;
}

/* Адаптивность (экраны < 768px) */
@media (max-width: 768px) {
  .property-container {
    flex-direction: column;
    overflow: visible;
  }

  .property-block {
    flex: none;
    width: 100%;
    margin: 10px 0;
    height: 100px;
    transition: none;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .property-block:hover {
    flex: none;
  }

  .property-title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .property-button {
    font-size: 14px;
    padding: 10px 16px;
    border-radius: 20px;
  }
}
