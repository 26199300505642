.clickable-hint {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: moveAndClick 15s infinite; /* Цикл анимации каждые 15 секунд */
    z-index: 3;
    pointer-events: none;
}

.hand-icon {
    width: 50px;
    height: auto;
    opacity: 0.8;
    animation: clickEffect 5s ease-in-out infinite;
}

.hint-text {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 1rem;
    border-radius: 8px;
}

/* Стиль ряби */
.ripple {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    animation: rippleEffect 1s ease-out;
    pointer-events: none;
    z-index: 2;
}

/* Анимация ряби */
@keyframes rippleEffect {
    0% {
        transform: scale(0);
        opacity: 0.8;
    }
    100% {
        transform: scale(12);
        opacity: 0;
    }
}

/* Анимация перемещения и исчезновения */
@keyframes moveAndClick {
    0% { top: 20%; right: 10%; opacity: 1; }
    20% { top: 20%; right: 10%; opacity: 1; }
    33% { top: 20%; right: 10%; opacity: 0; }
    34% { top: 40%; right: 25%; opacity: 0; }
    54% { top: 40%; right: 25%; opacity: 1; }
    67% { top: 40%; right: 25%; opacity: 0; }
    68% { top: 60%; right: 15%; opacity: 0; }
    88% { top: 60%; right: 15%; opacity: 1; }
    100% { top: 60%; right: 15%; opacity: 0; }
}

/* Анимация эффекта "клика" */
@keyframes clickEffect {
    0%, 100% { transform: scale(1); opacity: 1; }
    10% { transform: scale(1.3); opacity: 1; }
    12% { transform: scale(1); opacity: 1; }
    15% { transform: scale(1.2); opacity: 1; }
    17% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1); opacity: 0; }
}
