html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #060417;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  max-width: 1300px !important;
  margin: 0 auto;
  position: relative;
}

/* Только фиксация футера */
footer {
  margin-top: auto;
}
