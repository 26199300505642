.nav-wrapper {
    background: rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);
    display: flex; 
    justify-content: center; 
    transition: width 0.3s ease, padding 0.3s ease;
}

.nav-wrapper.scrolled {
    width: 100%; 
    padding: 0;
}

.nav-content {
    max-width: 1300px;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 2rem;
    transition: max-width 0.3s ease;
}

.logo {
    width: 7rem;
    height: auto;
    transition: width 0.3s ease;
}

.logo.scrolled-logo {
    width: 5rem;
}

.contact-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: font-size 0.3s ease;
}

.contact-info.scrolled-info .phone-number {
    font-size: 1.2em;
}

.icon {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease, font-size 0.3s ease;
    padding: 0.2rem;
}

.icon:hover {
    color: #a993fe;
}

.phone-number {
    font-size: 1.4em;
    font-weight: 500;
    color: #fff;
    text-decoration: none; /* Убираем подчеркивание у ссылки */
}

.phone-number:hover {
    color: #a993fe;
    text-decoration: none; /* Убираем подчеркивание при наведении */
}

.phone-icon {
    display: none; /* Скрываем иконку телефона по умолчанию */
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
}

.phone-icon:hover {
    color: #a993fe;
}

@media (max-width: 850px) {
    .logo {
        width: 4rem;
        height: auto;
    }

    .phone-number {
        display: none; /* Скрываем текст номера телефона на мобильных устройствах */
        font-size: 1.2rem;
    }

    .phone-icon {
        display: inline; /* Показываем иконку телефона на мобильных устройствах */
        padding: 0.2rem;
    }

    .icon {
        font-size: 1.5rem;
    }
}

@media (max-width: 1325px) {
    .nav-wrapper {
        padding: 0 2rem;
    }
}
