.footer {
    background-color: #1c1c2d;
    color: #ffffff;
    padding: 20px 0;
    font-family: 'Arial', sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-left {
    flex: 1;
}

.footer-logo {
    width: 6rem;
    margin-bottom: 20px;
}

.social-icons {
    display: flex;
    gap: 10px;
}

.icon {
    font-size: 1.5rem;
    color: #ffffff;
    cursor: pointer;
}

.icon:hover {
    color: #8675d3; /* Фиолетовый цвет при наведении */
}

.footer-contact {
    text-align: right;
    flex: 1;
}

.footer-contact p,
.footer-contact a {
    margin: 0.7rem 0;
}

.footer-contact a {
    color: #ffffff;
    text-decoration: none;
}

.contact-button {
    background: linear-gradient(110deg, #4a4a72, #2b2b42, #4a4a72);
    background-size: 200% 100%;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
    transform: translateY(0);
    letter-spacing: 0.5px;
    animation: contactButtonPulse 3s ease-in-out infinite,
               contactGradientMove 8s linear infinite;
}

.contact-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        120deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: 0.5s;
}

/* Анимация мягкой пульсации */
@keyframes contactButtonPulse {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
    }
    50% {
        transform: scale(1.02);
        box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
    }
}

/* Анимация движения градиента */
@keyframes contactGradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.contact-button:hover {
    background-position: right center;
    transform: translateY(-2px) scale(1.03);
    box-shadow: 0 8px 25px rgba(74, 74, 114, 0.5);
}

.contact-button:hover::before {
    left: 100%;
}

.contact-button:active {
    transform: translateY(1px) scale(0.98);
    box-shadow: 0 2px 10px rgba(74, 74, 114, 0.3);
}

.footer-info {
    color: #bbb;
    font-size: 0.85rem;
    max-width: 350px; /* Увеличена ширина */
    margin-top: 1.5rem;
    line-height: 1.5;
    text-align: justify; /* Выровнено по обеим сторонам */
}

.footer-info p {
    margin: 0;
}

.footer-bottom {
    text-align: center;
    margin-top: 3rem;
    font-size: 14px;
}

.footer-bottom a {
    color: #ffffff;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

/* Адаптивные стили для планшетов */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        padding: 0 15px;
    }

    .footer-left,
    .footer-contact {
        flex: none;
        text-align: center;
        margin-bottom: 20px;
    }

    .footer-logo {
        width: 5rem;
        margin: 0 auto 10px;
    }

    .social-icons {
        justify-content: center;
    }

    .contact-button {
        padding: 0.8rem 1.6rem;
        font-size: 0.9rem;
    }

    .footer-info {
        text-align: justify; /* Выровнено по обеим сторонам */
        max-width: 100%; /* Полная ширина на планшетах */
    }
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 480px) {
    .footer-logo {
        width: 4rem;
    }

    .footer-container {
        padding: 0 10px;
    }

    .footer-contact p,
    .footer-contact a,
    .contact-button {
        font-size: 14px;
    }

    .contact-button {
        padding: 0.7rem 1.4rem;
        font-size: 0.9rem;
    }

    .footer-bottom {
        font-size: 12px;
        padding: 0 10px;
    }

    .footer-info {
        font-size: 0.8rem;
        text-align: justify; /* Выровнено по обеим сторонам */
    }
}
