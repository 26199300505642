:root {
    --primary-color: #7c66e3;
    --secondary-color: #FF5733;
    --background-blur: 225px;
    --font-size-large: 2.3rem;
    --font-size-medium: 2rem;
    --font-size-small: 1.8rem;
    --font-size-mobile: 1.8rem;
    --line-height-large: 3rem;
    --line-height-medium: 2.2rem;
    --line-height-small: 1.8rem;
    --line-height-mobile: 1.8rem;
    --font-size-p: 1.1rem;
    --font-size-p-medium: 0.9rem;
    --font-size-p-small: 0.8rem;
    --font-line-height-p: 1.6rem;
    --font-line-height-p-medium: 1.4rem;
    --font-line-height-p-small: 1.2rem;
    --padding-top-large: 9rem;
    --padding-bottom-large: 2rem;
    --blur-circle-size: 28.125rem;
    --blur-circle-mobile-size: 18rem;
}

.hero-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: var(--padding-top-large);
    padding-bottom: var(--padding-bottom-large);
    position: relative;
}

.hero-content {
    flex: 1;
}

.hero-map {
    flex: 1;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible; /* Сделать overflow видимым, чтобы рябь не обрезалась */
  }
  

.moscow-map {
    height: auto;
    max-width: none;
}

.hero-container h2 {
    font-size: var(--font-size-large);
    font-weight: 600;
    line-height: var(--line-height-large);
    margin-bottom: 1rem;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.hero-content p {
    width: 80%;
    font-size: var(--font-size-p);
    font-weight: 400;
    line-height: var(--font-line-height-p);
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 0.2px;
}

.hero-container::after,
.hero-container::before {
    content: "";
    width: var(--blur-circle-size);
    height: var(--blur-circle-size);
    border-radius: 50%;
    background: var(--primary-color);
    position: absolute;
    z-index: 0; /* Повысил z-index для лучшего отображения */
    filter: blur(var(--background-blur));
}

.hero-container::after {
    top: 5rem;
    left: -5rem;
}

.hero-container::before {
    bottom: 2rem;
    right: 0;
}

/* Media Queries */
@media (max-width: 1350px) {
    .hero-container h2 {
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
    }

    .hero-content p {
        font-size: var(--font-size-p-medium);
        line-height: var(--font-line-height-p-medium);
    }
}

@media (max-width: 1025px) {
    .hero-container h2 {
        font-size: var(--font-size-small);
        line-height: var(--line-height-small);
    }

    .hero-content p {
        font-size: var(--font-size-p-small);
        line-height: var(--font-line-height-p-medium);
    }
}

@media (max-width: 768px) {
    .hero-container {
        flex-direction: column;
    }

    .hero-container h2 {
        margin-top: 3rem;
    }

    .hero-content h2,
    .hero-content p {
        width: auto;
        text-align: center;
    }

    .hero-container::after,
    .hero-container::before {
        width: var(--blur-circle-mobile-size);
        height: var(--blur-circle-mobile-size);
    }

    .hero-container::after {
        top: 0rem;
        left: 0rem;
    }

    .hero-container::before {
        bottom: 2rem;
        right: 0rem;
    }

    .hero-map {
        display: none; /* Скрыть карту на мобильных устройствах */
    }
}

@media (max-width: 600px) {
    .hero-container h2 {
        font-size: var(--font-size-mobile);
        line-height: var(--line-height-mobile);
    }

    .hero-content p {
        font-size: var(--font-size-p-small);
        line-height: var(--font-line-height-p-small);
    }
}

/* Карта и регионы */
.region {
    fill: #A8BFE4;
    transition: fill 0.3s ease;
}

.region:hover {
    fill: var(--secondary-color);
    cursor: pointer;
}

.moscow-map path {
    stroke: var(--primary-color);
    stroke-width: 2px;
    fill: #A8BFE4;
    transition: fill 0.5s ease, transform 0.3s ease;
    opacity: 0;
    animation: fadeIn 1s forwards ease-in-out, pulse 3s infinite;
    transform-origin: center;
}

.moscow-map path:hover {
    fill: var(--secondary-color);
    transition: fill 0.5s ease;
}

/* Анимации */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        fill: #A8BFE4;
    }
    50% {
        transform: scale(1.05);
        fill: var(--primary-color);
    }
    100% {
        transform: scale(1);
        fill: #A8BFE4;
    }
}
