/* src/components/Hero/Effects/ArrowHint/ArrowHint.css */

.arrow-hint-container {
    position: absolute;
    bottom: 10%; /* Позиционируем рядом с картой */
    left: 5%; /* Смещаем от левого края */
    display: flex;
    flex-direction: row; /* Размещаем элементы в строку */
    align-items: center;
    z-index: 5;
}

.arrow-text {
    margin-right: 20px; /* Отступ между текстом и стрелкой */
    font-size: 1.2rem;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-align: center;
    animation: pulse 1.5s infinite ease-in-out; /* Применяем ту же анимацию, что и для стрелки */
}

.arrow-hint {
    width: 150px; /* Размер стрелки */
    height: 150px; /* Размер стрелки */
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}
