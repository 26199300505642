.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  color: #fff;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-banner-content {
  max-width: 1300px;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-banner p {
  margin: 0;
  font-size: 0.875rem; /* Уменьшенный размер текста */
  color: #fff;
}

.accept-button {
  background-color: rgba(22, 17, 47, 0.6); /* Полупрозрачный фон */
  color: #fff;
  padding: 8px 16px; /* Уменьшенный padding для уменьшения размера кнопки */
  border: 2px solid #8675d3; /* Обводка */
  border-radius: 6px; /* Немного меньший радиус */
  cursor: pointer;
  font-size: 0.8rem; /* Уменьшенный размер текста */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Легкая тень для объема */
  margin-left: 20px; /* Отступ от текста */
}

.accept-button:hover {
  background-color: rgba(22, 17, 47, 0.8); /* Темнее фон при наведении */
  border-color: #fff; /* Белая обводка при наведении */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Более выраженная тень при наведении */
}

@media (max-width: 768px) {
  .cookie-banner-content {
      flex-direction: column;
      padding: 1rem;
  }

  .accept-button {
      margin-top: 0.5rem;
      margin-left: 0; /* Убираем отступ для мобильных устройств */
  }
}
