.catalog-container {
    margin: 5rem 0;
  }

  .catalog-card {
    flex: 1 1 calc(33.333% - 1rem); /* Distribute cards evenly with gap */
    justify-content: center;
    width: 100%; /* Make each card take full width */
  }
  
  .catalog-settings {
    display: flex;
    justify-content: space-between;
    gap: 0.2rem; /* Adjust gap between cards */
    width: 100%; /* Make sure the settings container takes full width */
  }
  

  .catalog-card-container {
    transition: opacity 0.5s ease, height 0.5s ease;
    overflow: hidden;
    opacity: 1;
    height: auto;
    min-height: 500px; /* Задаем минимальную высоту для контейнера */
  }
  
  .catalog-card-container.hidden {
    opacity: 0;
    height: 0;
    min-height: 0; /* Убираем минимальную высоту, если контейнер скрыт */
  }

  .map-container {
    height: 500px; /* Задаем фиксированную высоту для карты */
  }
  
  .icon-spacing {
    margin-left: 8px; /* Задаем отступ между текстом и иконкой */
    vertical-align: middle; /* Выровняйте иконку по вертикали с текстом, если нужно */
  }
  
  @media (max-width: 1024px) {
    .catalog-card {
      flex: 1 1 100%; /* Make each card take full width on mobile */
      width: 100%;
    }
  
    .catalog-card.card-2,
    .catalog-card.card-3 {
      display: none; /* Hide the second and third cards on mobile */
    }
  }