/* Скелетон карточки */
.skeleton-card {
  background: linear-gradient(90deg, rgba(192, 192, 192, 0.3) 25%, rgba(192, 192, 192, 0.5) 50%, rgba(192, 192, 192, 0.3) 75%);
  background-size: 200% 100%;
  width: 100%;
  height: 300px; /* Высота карточки */
  border-radius: 8px;
  animation: wave 1.2s infinite ease-in-out; /* Анимация волны */
  margin: 0; /* Убираем внешние отступы */
  opacity: 0.2; /* Полупрозрачность */
}

/* Анимация для волны */
@keyframes wave {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* По умолчанию 3 колонки для полного экрана */
  gap: 0.2rem; /* Промежутки между элементами */
}

.grid-item {
  position: relative;
  width: 100%;
  padding-top: 70%; /* Высота задана как 70% от ширины */
  background-color: #292152; /* Пример фонового цвета */
  box-sizing: border-box; /* Включаем border и padding в общие размеры */
  overflow: hidden; /* Обрезаем содержимое, если выходит за пределы */
}

.grid-item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.load-more-container {
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;
}

.load-more-button {
  width: 100%;
  background: linear-gradient(110deg, #4a4a72, #2b2b42, #4a4a72);
  background-size: 200% 100%;
  color: #fff;
  border: none;
  padding: 16px 32px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  transform: translateY(0);
  letter-spacing: 0.5px;
  animation: loadMorePulse 3s ease-in-out infinite,
             loadMoreGradient 8s linear infinite;
}

.load-more-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

/* Анимация мягкой пульсации */
@keyframes loadMorePulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  }
}

/* Анимация движения градиента */
@keyframes loadMoreGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.load-more-button:hover {
  background-position: right center;
  transform: translateY(-2px) scale(1.03);
  box-shadow: 0 8px 25px rgba(74, 74, 114, 0.5);
}

.load-more-button:hover::before {
  left: 100%;
}

.load-more-button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 2px 10px rgba(74, 74, 114, 0.3);
}

/* Планшеты и десктопы */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 колонки для планшетов */
  }
}

/* Мобильные устройства (ширина до 768px) */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* 1 колонка для мобильных устройств */
  }
  
  .load-more-button {
    padding: 14px 28px;
    font-size: 14px;
  }
  
  .load-more-container {
    width: 100%; /* Убрано ограничение max-width */
  }
}
