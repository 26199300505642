.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 7rem;
  color: #fff;
  background-color: #060417;
  line-height: 1.6;
}

.privacy-policy h1, .privacy-policy h2 {
  margin-top: 1.5rem;
}

.privacy-policy p {
  margin-bottom: 1.5rem;
}

/* Адаптивные стили для планшетов */
@media (max-width: 768px) {
  .privacy-policy {
      padding: 1rem; /* Уменьшаем отступы */
  }

  .privacy-policy h1 {
      font-size: 2rem; /* Уменьшаем размер заголовка */
  }

  .privacy-policy h2 {
      font-size: 1.5rem; /* Уменьшаем размер подзаголовков */
  }
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 480px) {
  .privacy-policy {
      padding: 0.5rem; /* Ещё сильнее уменьшаем отступы */
  }

  .privacy-policy h1 {
      font-size: 1.8rem; /* Ещё сильнее уменьшаем размер заголовка */
  }

  .privacy-policy h2 {
      font-size: 1.2rem; /* Уменьшаем размер подзаголовков */
  }

  .privacy-policy p {
      font-size: 0.9rem; /* Уменьшаем размер текста для мобильных устройств */
      line-height: 1.4; /* Уменьшаем межстрочный интервал */
  }
}
