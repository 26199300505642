.container {
  max-width: 1300px !important;
  margin: 0 auto;
  position: relative;
}

/* Устанавливаем цвет для всех ссылок */
a {
  color: #fff; /* Замените на нужный цвет, например, черный */
  text-decoration: none; /* Убираем подчеркивание, если нужно */
}

/* Если хотите изменить цвет ссылки при наведении мыши */
a:hover {
  color: #8675d3; /* Замените на нужный цвет, например, красный */
  text-decoration: underline; /* Подчеркивание при наведении */
}

@media (max-width: 1299px) {
  .nav-wrapper {
    padding: 0 1.5rem;
  }

  .container {
    margin: 0 2rem;
  }


}


@media (max-width: 768px) {
  .container {
    margin: 0 2rem;
  }
}