.quiz-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(10, 15, 25, 0.95) 25%,
    rgba(10, 15, 25, 0.85) 45%,
    rgba(10, 15, 25, 0.6) 70%,
    rgba(10, 15, 25, 0.3) 90%,
    transparent 100%
  );
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.quiz-content-wrapper {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.quiz-start-content {
  max-width: 600px;
  color: #fff;
  text-align: left;
  margin-left: 50px;
}

.quiz-start-content h1 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: bold;
}

.quiz-start-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.start-button {
  background-color: rgba(193, 157, 101, 0.9);
  color: #000;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, opacity 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.6;
}

.start-button:hover {
  background-color: rgba(212, 176, 124, 0.9);
  transform: scale(1.05);
  opacity: 1;
}

.home-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.home-button {
  position: absolute;
  bottom: 40px; /* Отступ от нижнего края экрана */
  left: 50%; /* Выравнивание по горизонтали */
  transform: translateX(-50%); /* Центрирование кнопки */
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #fff;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  backdrop-filter: blur(8px);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.home-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateX(-50%) scale(1.05);
}

/* Адаптивность для планшетов и мобильных устройств */
@media (max-width: 1200px) {
  .quiz-content-wrapper {
    padding: 0 10px;
  }

  .quiz-start-content h1 {
    font-size: 2.2rem;
  }

  .quiz-start-content p {
    font-size: 1.1rem;
  }

  .start-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .quiz-content-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .quiz-start-content {
    margin-left: 0;
    max-width: 100%;
  }

  .quiz-start-content h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .quiz-start-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .start-button {
    padding: 12px 20px;
    font-size: 1rem;
  }

  .home-button {
    bottom: 30px;
    left: 50%; /* Сохраняем центрирование */
    transform: translateX(-50%);
    padding: 8px 15px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .quiz-start-content h1 {
    font-size: 1.8rem;
  }

  .quiz-start-content p {
    font-size: 0.9rem;
  }

  .start-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .home-button {
    bottom: 20px;
    left: 50%; /* Центрируем */
    transform: translateX(-50%);
    padding: 6px 12px;
    font-size: 0.7rem;
  }
}
