/* Размытие фона */
.modal-call-back-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px); /* Эффект размытия */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

/* Контейнер модального окна */
.modal-call-back-container {
  background: linear-gradient(135deg, #23233c, #2d2d49);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 100%;
  text-align: center;
  position: relative;
}

/* Кнопка закрытия */
.modal-call-back-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  width: 40px;
  height: 40px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-call-back-close-button:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* Заголовок */
.modal-call-back-title {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 15px;
}

/* Описание */
.modal-call-back-description {
  font-size: 1rem;
  color: #dcdcdc;
  margin-bottom: 20px;
}

/* Поля ввода */
.modal-call-back-input {
  width: calc(100% - 30px); /* Отнимаем 30px для равного отступа слева и справа */
  padding: 15px;
  margin: 0 auto 15px; /* Отступы для равного выравнивания */
  background: #29293d;
  color: #fff;
  border: 1px solid #444;
  border-radius: 8px;
  transition: border 0.3s ease;
  box-sizing: border-box;
}

.modal-call-back-input:focus {
  border: 1px solid #7a7ae0;
  outline: none;
}

/* Кнопка отправки */
.modal-call-back-submit-btn {
  background-color: #C19D65; /* Цвет кнопки, как в DownloadCatalog */
  color: #000; /* Черный текст */
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, opacity 0.3s ease; /* Добавлен transition для opacity */
  text-transform: uppercase; /* Добавлено uppercase */
  letter-spacing: 1px; /* Увеличенный межбуквенный интервал */
  opacity: 0.9; /* Прозрачность кнопки */
  width: 100%; /* Ширина кнопки на всю ширину контейнера */
}

.modal-call-back-submit-btn:hover {
  background-color: #d4b07c; /* Цвет кнопки при наведении, как в DownloadCatalog */
  transform: scale(1.05);
  opacity: 1; /* Убираем прозрачность при наведении */
}

/* Примечание */
.modal-call-back-note {
  font-size: 0.85rem;
  color: #bdbdbd;
  margin-top: 15px;
}

.modal-call-back-privacy-link {
  color: #a089ff;
  text-decoration: underline;
}

.modal-call-back-privacy-link:hover {
  color: #c4b5ff;
}

/* Для мобильных экранов */
@media (max-width: 768px) {
  .modal-call-back-container {
    padding: 20px;
  }

  .modal-call-back-title {
    font-size: 1.5rem;
  }

  .modal-call-back-input {
    padding: 12px;
    width: calc(100% - 20px); /* Для более узких экранов уменьшаем ширину */
  }

  .modal-call-back-submit-btn {
    padding: 12px 20px;
    font-size: 1rem;
  }
}
