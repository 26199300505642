.catalog-settings-card {
  position: relative;
  background: linear-gradient(135deg, #2a2a44, #1b1b30); /* Спокойный, темный градиент под стиль сайта */
  border-radius: 10px;
  border: 1px solid #605a9e; /* Более светлый тон для рамки */
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); /* Легкая тень */
}

.catalog-settings-card:hover {
  background: linear-gradient(135deg, #1b1b30, #2a2a44); /* Немного меняем направление градиента при наведении */
  border-color: #8675d3; /* Легкий световой эффект при наведении */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Немного увеличенная тень при наведении */
}

.filter-label {
  display: flex;
  align-items: center;
  color: #e5e5f5; /* Легкий светлый оттенок для текста */
  font-size: 1rem; /* Спокойный, не слишком крупный размер текста */
}

.filter-count {
  background-color: #5050a5;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(34, 34, 56, 0.9);
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  z-index: 10;
  margin-top: 10px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
}

.dropdown-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.dropdown-item input {
  margin-right: 1.2rem;
}

.apply-button,
.clear-button {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button {
  background-color: #5050a5;
  color: #fff;
  margin-top: 1rem;
}

.apply-button:hover {
  background-color: #404085;
}

.clear-button {
  background-color: #b94a48;
  color: #fff;
  margin-top: 1rem;
}

.clear-button:hover {
  background-color: #a94442;
}

/* Адаптивные стили для планшетов и мобильных устройств */
@media (max-width: 768px) {
  .catalog-settings-card {
    font-size: 0.9rem;
    border-radius: 8px;
  }

  .filter-label {
    font-size: 0.9rem;
  }

  .dropdown {
    padding: 0.8rem;
  }

  .apply-button, 
  .clear-button {
    padding: 8px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .catalog-settings-card {
    font-size: 0.8rem;
  }

  .filter-label {
    font-size: 0.8rem;
  }

  .apply-button, 
  .clear-button {
    padding: 6px;
    font-size: 0.8rem;
  }
}
