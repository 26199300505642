.floating-socials {
    position: fixed;
    bottom: 40px; /* Увеличенное расстояние от нижнего края */
    right: 40px; /* Увеличенное расстояние от правого края */
    z-index: 1000;
  }
  
  .floating-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: linear-gradient(110deg, #4a4a72, #2b2b42, #4a4a72);
    background-size: 200% 100%;
    color: #fff;
    border: none;
    border-radius: 14px;
    padding: 16px 28px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
    transition: all 0.3s ease;
    letter-spacing: 0.3px;
    animation: floatingGradient 8s linear infinite,
               floatingPulse 3s ease-in-out infinite;
  }
  
  @keyframes floatingGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes floatingPulse {
    0% {
      transform: scale(1);
      box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
    }
    50% {
      transform: scale(1.02);
      box-shadow: 0 8px 25px rgba(74, 74, 114, 0.5);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
    }
  }
  
  .floating-button.expanded {
    background: #2b2b42;
    font-size: 20px;
    animation: none;
  }
  
  .floating-button:hover {
    transform: translateY(-3px) scale(1.03);
    box-shadow: 0 8px 30px rgba(74, 74, 114, 0.5);
  }
  
  .blinking-dot {
    width: 12px;
    height: 12px;
    background-color: #00baff;
    border-radius: 50%;
    box-shadow: 0 0 15px #00baff;
    animation: softBlink 2s ease-in-out infinite;
    margin-left: 5px; /* Добавляем отступ слева */
  }
  
  @keyframes softBlink {
    0%, 100% {
      opacity: 1;
      transform: scale(1);
      box-shadow: 0 0 15px #00baff;
    }
    50% {
      opacity: 0.7;
      transform: scale(0.85);
      box-shadow: 0 0 8px #00baff;
    }
  }
  
  .socials-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    margin-top: 15px;
  }
  
  .social-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    background: #fff;
    color: #000;
    padding: 8px 12px;
    border-radius: 25px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    width: 140px; /* Уменьшенная фиксированная ширина */
  }
  
  .social-item:hover {
    background: #f5f5f5;
  }
  
  .social-icon {
    font-size: 20px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
  }
  
  .social-icon.whatsapp {
    background-color: #25d366;
    color: #fff;
  }
  
  .social-icon.telegram {
    background-color: #0088cc;
    color: #fff;
  }
  
  .social-icon.vk {
    background-color: #4680c2;
    color: #fff;
  }
  
  .social-icon.phone {
    background-color: #ffc107;
    color: #fff;
  }
  