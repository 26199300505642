.apartment-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  padding: 20px; /* Добавлен отступ для контента */
}

.metro-info {
  margin-top: 15px;
  padding: 10px;
  background: linear-gradient(145deg, #1e1e33, #2b2b42);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.empty-tags-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 10px;
}

.metro-station {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  padding: 8px 12px;
  background-color: #252540;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.1);
}

.metro-name {
  font-weight: bold;
}

.metro-distance {
  color: #ffffff;
  opacity: 0.8;
  font-size: 13px;
}

.metro-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.first-block {
  display: flex;
  height: 450px;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #141427; /* Убран фон, так как он теперь на body */
  border-radius: 20px;
  position: relative;
}

.apartment-info-block {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  box-sizing: border-box;
  padding-right: 4%;
}

.apartment-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.apartment-stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
  padding: 15px 20px;
  background: linear-gradient(145deg, #1e1e33, #2b2b42);
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.apartment-stat-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  color: #ffffff;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.stat-label {
  font-size: 14px;
  color: #a5a5a5;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.apartment-details {
  margin-top: 15px;
  padding: 15px 20px;
  background: linear-gradient(145deg, #1e1e33, #2b2b42);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.detail {
  display: flex;
  flex-direction: column;
}

.detail:nth-child(2) {
  text-align: right;
}

.detail-title {
  font-size: 14px;
  color: #a5a5a5;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.detail-value {
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.apartment-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-button {
  background: #2b2b42;
  color: #00baff;
  padding: 6px 12px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.tag-button:hover {
  background: #00baff;
  color: #2b2b42;
}

.apartment-image-top {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 100px;
}

.quiz-and-media-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  max-width: 1220px; /* Уменьшено с 1260px */
  margin: 0;
  box-sizing: border-box;
 /* Изменено с padding: 0 20px на padding-right */
}

.quiz-section {
  flex: 0 0 calc(50% - 10px);
  background-color: #1e1e33;
  padding: 20px;
  border-radius: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #ccc;
  min-height: 200px;
  
}

.quiz-section p {
  margin-bottom: 20px;
  font-size: 18px;
}

.quiz-button {
  background: linear-gradient(110deg, #4a4a72, #2b2b42, #4a4a72);
  background-size: 200% 100%;
  color: #fff;
  border: none;
  padding: 20px 40px; /* Увеличены отступы */
  border-radius: 14px; /* Немного увеличен радиус */
  cursor: pointer;
  font-size: 18px; /* Увеличен размер шрифта */
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  transform: translateY(0);
  letter-spacing: 0.5px;
  animation: softPulse 3s ease-in-out infinite, 
             gradientMove 8s linear infinite; /* Добавлены постоянные анимации */
}

.quiz-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

/* Анимация мягкой пульсации */
@keyframes softPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 6px 20px rgba(74, 74, 114, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(74, 74, 114, 0.3);
  }
}

/* Анимация движения градиента */
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.quiz-button:hover {
  background-position: right center;
  transform: translateY(-2px) scale(1.03); /* Немного увеличиваем при наведении */
  box-shadow: 0 8px 25px rgba(74, 74, 114, 0.5);
}

.quiz-button:hover::before {
  left: 100%;
}

.quiz-button:active {
  transform: translateY(1px) scale(0.98);
  box-shadow: 0 2px 10px rgba(74, 74, 114, 0.3);
}

.quiz-button.disabled {
  background: #2b2b42;
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  animation: none; /* Отключаем анимацию для disabled состояния */
}

.quiz-button.disabled:hover {
  transform: none;
  box-shadow: none;
}

.quiz-button.disabled::before {
  display: none;
}

.media-container {
  flex: 0 0 calc(50% - 10px);
  display: flex;
  gap: 20px;
}

.video-container,
.pdf-container {
  flex: 1;
  background-color: #1e1e33;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  min-height: 200px;
}

.video-unlocked {
  width: 100%;
  height: auto;
  position: relative;
}

.video-bg {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.play-icon {
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.play-icon:hover {
  transform: translate(-50%, -50%) scale(1.1);
  opacity: 0.8;
}

.pdf-unlocked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pdf-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00baff;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.pdf-link:hover {
  transform: scale(1.1);
  color: #ffffff;
  text-decoration: none;
}

.pdf-link img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.pdf-link img:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.pdf-link span {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.overlay img {
  width: 40px;
  margin-bottom: 10px;
}

.overlay p {
  font-size: 14px;
}

.single-media-container {
  flex: 0 0 calc(50% - 10px);
  background-color: #1e1e33;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  min-height: 200px;
  box-sizing: border-box;
  margin: 0;
}

.unlocked-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-presentation {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00baff;
  cursor: pointer; /* Курсор-указатель */
  transition: transform 0.3s ease, color 0.3s ease;
}

.download-presentation:hover {
  transform: scale(1.1);
  color: #ffffff;
  text-decoration: none; /* Убирает подчеркивание при наведении */
}

.download-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.download-icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.download-presentation span {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-decoration: none; /* Убирает подчеркивание текста */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-video {
  width: 80%;
  max-width: 900px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease-in-out;
}

.loading-message {
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  color: #ddd;
}

@media (max-width: 1300px) {
  .quiz-and-media-container {

 
    width: 100% !important;
    max-width: none !important;
    margin: 0 !important;
    gap: 20px;
    padding-right: 40px; /* Уменьшенный отступ для смартфонов */
  }

}


@media (max-width: 768px) {
  .first-block {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .apartment-info-block {
    max-width: 100%;
    padding-right: 0;
  }

  .apartment-image-top {
    position: static;
    width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 15px;
  }

  .apartment-stats {
    flex-direction: row;
    align-items: center;
  }

  .quiz-and-media-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    max-width: none !important;
    margin: 0 !important;
    gap: 20px;
    padding-right: 0px; /* Уменьшенный отступ для смартфонов */
  }

  .quiz-section {
    flex: 0 0 100% !important;
    width: 100% !important;
    margin: 0;
    box-sizing: border-box;
  }

  .media-container {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between;
    width: 100% !important;
    margin: 0;
    padding: 0;
    gap: 10px;
    box-sizing: border-box;
  }

  .video-container,
  .pdf-container {
    flex: 0 0 calc(50% - 10px) !important;
    margin: 0;
    box-sizing: border-box;
    min-height: auto;
  }

  .apartment-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .detail-title,
  .stat-label {
    font-size: 12px;
  }

  .detail-value,
  .stat-value {
    font-size: 16px;
  }

  .tag-button {
    padding: 4px 8px;
    font-size: 14px;
  }

  .quiz-button {
    width: 100%;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 20px;
  }

  .video-container,
  .pdf-container {
    min-height: 160px;
  }

  .modal-video {
    width: 90%;
  }
}